<template>
  <div class="meteo-in-campus">
    <div class="mic-wrap">
      <div class="mic-title">
        气象知识进校园
        <div class="english-title">WEATHER KNOWLEDGE ENTERS THE CAMPUS</div>
      </div>
      <div class="mic-content-wrap">
        <ul>
          <li v-for="(item, index) in micInfo" :key="index" class="mic-content-item">
            <img :src="item.bgiUri" alt="">
            <i><img :src="item.iconUri" alt=""></i>
            <div class="mic-content-item-bottom">
              <div class="mic-content-item-title">{{item.title}}</div>
              <div class="mic-content-item-content">{{item.content}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeteoInCampus',
  data() {
    return {
      micInfo: [
        {bgiUri: require('@assets/img/rainbowchalk/bgi1.png'), iconUri: require('@assets/img/rainbowchalk/mic_icon1.png'), title: '气象课堂', content: '气象专家给学生讲解日常生活相关的气象知识balabalabalabala'},
        {bgiUri: require('@assets/img/rainbowchalk/bgi2.png'), iconUri: require('@assets/img/rainbowchalk/mic_icon2.png'), title: '气象知识竞赛', content: '气象专家给学生讲解日常生活相关的气象知识'},
        {bgiUri: require('@assets/img/rainbowchalk/bgi3.png'), iconUri: require('@assets/img/rainbowchalk/mic_icon3.png'), title: '2021年北京市科普知识竞赛', content: '气象专家给学生讲解日常生活相关的气象知识,气象专家给学生讲解日常生活相关的气象知识,气象专家给学生讲解日常生活相关的气象知识'}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .meteo-in-campus {
    width: 100%;
    height: 778px;
    background-color: #1b2839;
    padding-top: 110px;
    .mic-wrap {
      width: 1206px;
      height: 520px;
      margin: 0 auto;
      .mic-title {
        position: relative;
        width: 564px;
        height: 47px;
        line-height: 47px;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 3px;
        margin: 0 auto 85px;
        .english-title {
          height: 25px;
          line-height: 25px;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .mic-content-wrap {
        width: 100%;
        height: 340px;
        ul {
          .mic-content-item {
            position: relative;
            float: left;
            width: 380px;
            height: 340px;
            border-radius: 10px;
            background-color: #2d4360;
            box-shadow: 0 2px 6px 0 rgba(255, 255, 255, .1);
            overflow: hidden;
            transition: .3s;
            &:nth-of-type(-n+2) {
              margin-right: 30px;
            }
            img {
              display: block;
              width: 100%;
              height: 212px;
            }
            i {
              position: absolute;
              top: 180px;
              left: 50%;
              transform: translateX(-50%);
              width: 55px;
              height: 55px;
              img {
                display: block;
                width: 55px;
                height: 55px;
              }
            }
            .mic-content-item-bottom {
              padding: 35px 20px 10px;
              .mic-content-item-title {
                width: 100%;
                height: 30px;
                font-size: 20px;
                margin-bottom: 10px;
              }
              .mic-content-item-content {
                font-size: 12px;
                // 首行缩进
                text-indent: 30px;
                // 2行文本溢出 省略号
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                // 设置或检索伸缩盒对象的子元素的排列方式 垂直居中对齐
                -webkit-box-orient: vertical;
              }
            }
            // hover 动效
            &:hover {
              transform: translate(0, -10px);
              transition: .3s;
              background-color: #3d5b81;
            }
          }
        }
      }
    }
  }
</style>