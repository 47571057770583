<template>
  <div class="meteo-topic-video">
    <div class="mtv-wrap">
      <div class="mtv-title">
        气象科普专题
        <div class="english-title">METEOROLOGICAL SCIENCE TOPICS</div>
      </div>
      <video src="@assets/img/rainbowchalk/meteo_video.mp4" controls preload="auto" poster="@assets/img/rainbowchalk/meteo_video_preload.png"></video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeteoTopicVideo',
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .meteo-topic-video {
    width: 100%;
    height: 760px;
    background-color: #cdd8f6;
    padding-top: 110px;
    .mtv-wrap {
      width: 1206px;
      height: 500px;
      margin: 0 auto;
      .mtv-title {
        position: relative;
        width: 564px;
        height: 47px;
        margin: 0 auto 85px;
        line-height: 47px;
        text-align: center;
        color: #101074;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 3px;
        .english-title {
          height: 25px;
          line-height: 25px;
          color: #101074;
          font-size: 16px;
          font-weight: 400;
        }
      }
      video {
        width: 748px;
        height: 350px;
        background-color: #000;
      }
    }
  }
</style>