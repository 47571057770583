<template>
  <div class="rainbow-chalk">
    <Banner v-bind:bannerInfo="rainbowchalkBannerInfo"></Banner>
    <MeteoInCampus></MeteoInCampus>
    <MeteoActivity></MeteoActivity>
    <Presentation></Presentation>
    <MeteoTopicVideo></MeteoTopicVideo>
    <Footer></Footer>
  </div>
</template>

<script>
  import Banner from '@components/content/Banner.vue'
  import MeteoInCampus from './childComps/MeteoInCampus.vue'
  import MeteoActivity from './childComps/MeteoActivity.vue'
  import Presentation from './childComps/Presentation.vue'
  import MeteoTopicVideo from './childComps/MeteoTopicVideo.vue'
  import Footer from '@components/common/Footer.vue'

  export default {
    name: 'RainbowChalk',
    components: {
      Banner,
      MeteoInCampus,
      MeteoActivity,
      Presentation,
      MeteoTopicVideo,
      Footer
    },
    data() {
      return {
        rainbowchalkBannerInfo: [
          {imgUri: require("@assets/img/rainbowchalk/rainbowchalkbn1.png"), buttonFlag: 'true', buttonLeft: '选课', buttonRight: '联系我们'},
          {imgUri: require("@assets/img/rainbowchalk/rainbowchalkbn2.png"), buttonFlag: 'true', buttonLeft: '选课', buttonRight: '联系我们'},
          {imgUri: require("@assets/img/rainbowchalk/rainbowchalkbn3.png"), buttonFlag: 'true', buttonLeft: '选课', buttonRight: '联系我们'}
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .rainbow-chalk {
    width: 100%;
    text-align: center;
    background-color: #1b2839;
  }
</style>