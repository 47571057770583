<template>
  <div class="meteo-activity">
    <div class="ma-wrap">
      <ul>
        <li v-for="(item, index) in micInfo" :key="index" class="ma-content-item">
          <div class="item-list">
            <i class="decorate-icon"><img :src="item.iconUri" alt=""></i>
            <span>0{{index+1}}</span>
          </div>
          <div class="item-bottom">
            <div class="item-title">
              {{item.title}}
              <div>{{item.englishTitle}}</div>
            </div>
            <div class="item-content">{{item.content}}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeteoActivity',
  data() {
    return {
      micInfo: [
        { iconUri: require('@assets/img/rainbowchalk/decorate.png'), title: 'stem科普课程', englishTitle: 'Stem Science Course', content: '集“科学知识、动手操作和实践探究”于一体。内容涉及天气、物候、传统文化等方面知识。致力于“玩转科学 乐享其中”的理念，构建系统气象科普模式。帮助增强学生科学素养，培养学生自信心。'},
        { iconUri: require('@assets/img/rainbowchalk/decorate.png'), title: '研学实践', englishTitle: 'Research practice', content: '通过参观科普基地、气象研学基地等气象研学实践活动，孩子们身临其境地体验天气预报制作过程，参与特殊天气现象的会商，对气象观测、服务、灾害防御深入理解，提高自身的气象防灾抗灾意识。'},
        { iconUri: require('@assets/img/rainbowchalk/decorate.png'), title: '科普活动', englishTitle: 'Popular science activities', content: '通过参观科普基地、气象研学基地等气象研学实践活动，孩子们身临其境地体验天气预报制作过程，参与特殊天气现象的会商，对气象观测、服务、灾害防御深入理解，提高自身的气象防灾抗灾意识。'}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .meteo-activity {
    width: 100%;
    height: 618px;
    background-color: #cdd8f6;
    padding-top: 140px;
    .ma-wrap {
      width: 1206px;
      height: 330px;
      margin: 0 auto;
      ul {
        .ma-content-item {
          position: relative;
          float: left;
          width: 380px;
          height: 340px;
          padding-top: 35px;
          border-radius: 10px;
          background-color: #2d4360;
          box-shadow: 0 2px 6px 0 rgba(255, 255, 255, .1);
          overflow: hidden;
          transition: .3s;
          &:nth-of-type(-n+2) {
            margin-right: 30px;
          }
          .item-list {
            position: relative;
            width: 100%;
            height: 43px;
            i {
              position: absolute;
              top: 0;
              left: 155px;
              img {
                display: block;
                width: 43px;
                height: 43px;
                z-index: 9;
              }
            }
            span {
              position: absolute;
              top: -5px;
              left: 185px;
              font-size: 38px;
              font-weight: 700;
            }
          }
          .item-bottom {
            padding: 35px 20px 10px;
            .item-title {
              width: 100%;
              height: 40px;
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 25px;
              div {
                font-size: 12px;
                font-weight: 400;
              }
            }
            .item-content {
              text-align: left;
              font-size: 12px;
              line-height: 24px;
              // 首行缩进
              text-indent: 30px;
              // 2行文本溢出 省略号
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              // 设置或检索伸缩盒对象的子元素的排列方式 垂直居中对齐
              -webkit-box-orient: vertical;
            }
          }
          // hover 动效
          &:hover {
            transform: translate(0, -10px);
            transition: .3s;
            background-color: #3d5b81;
          }
        }
      }
    }
  }
</style>